'use client'

import Container from '@tebuto/layout/Container'
import { TRANSLATIONS } from './translations'
import { Heading } from './LandingComponents'
import { FAQContainer, FAQItem } from '@tebuto/layout/FAQ'
import { TebutoStructuredData } from '@/config/seo'
import { Matomo } from '@tebuto/providers/Matomo'

export default function FAQ(): JSX.Element {
    return (
        <Container id="faq" className="py-32">
            <Matomo.ScrollTrackingAnchor sectionName={'FAQ'} />
            <div className="mx-auto max-w-6xl">
                <Heading className="mb-24 max-sm:text-center">{TRANSLATIONS.landing.FAQ.title}</Heading>
                <div className="divide-y divide-gray-900/10 my-16">
                    <FAQContent />
                </div>
            </div>
        </Container>
    )
}

export function FAQContent(): JSX.Element {
    return (
        <>
            <TebutoStructuredData.FAQ name="Häufig gestellte Fragen zu Tebuto" items={TRANSLATIONS.landing.FAQ.items} />
            <FAQContainer>
                {TRANSLATIONS.landing.FAQ.items.map(faq => (
                    <FAQItem key={faq.question} question={faq.question} answer={faq.answer} />
                ))}
            </FAQContainer>
        </>
    )
}
