import { createInstance } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next/initReactI18next'

const initI18next = async (ns: string) => {
    const i18nInstance = createInstance()
    await i18nInstance
        .use(initReactI18next)
        .use(resourcesToBackend((language: string, namespace: string) => import(`../../public/locales/${language}/${namespace}.json`)))
        .init({ lng: 'de', preload: ['de'], ns: ['common', ns] })
    return i18nInstance
}

export async function getServerSideTranslations(ns: string) {
    const i18nextInstance = await initI18next(ns)
    return {
        t: i18nextInstance.getFixedT('de', ['common', ns]),
        i18n: i18nextInstance
    }
}
