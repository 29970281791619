import { ContactInfo } from '@tebuto/api/public/models/ContactInfo'
import { PublicTherapist } from '@tebuto/api/public/models/PublicTherapist'
import { TherapistCharacteristic } from '@tebuto/api/public/models/TherapistCharacteristic'
import { DateTime } from 'luxon'
import { ProfessionalService, WithContext } from 'schema-dts'

const DEFAULT_METER_RADIUS = 10000 // Could be a setting in the future
const DEFAULT_PRICE_RANGE = '$$$' // Could be a setting in the future or be calculated based on the average therapists hourly rate of categories with a price > 0

export function JsonLdTherapist({ therapist }: { therapist: PublicTherapist }): WithContext<ProfessionalService> {
    const contactInfos = therapist.contactInfos.filter(info => info.type === ContactInfo.type.EMAIL || info.type === ContactInfo.type.PHONE)
    const profiles = therapist.contactInfos.filter(info => info.type === ContactInfo.type.SOCIAL_MEDIA || info.type === ContactInfo.type.WEBSITE)

    return {
        '@context': 'https://schema.org',
        '@type': 'ProfessionalService',
        '@id': `https://tebuto.de/#${therapist.slug}`,
        name: therapist.name,
        url: therapist.contactInfos.find(information => information.type === 'website')?.value,
        email: therapist.contactInfos.find(information => information.type === 'email')?.value,
        telephone: therapist.contactInfos.find(information => information.type === 'phone')?.value,
        image: therapist.image || undefined,
        description: therapist.description || undefined,
        priceRange: DEFAULT_PRICE_RANGE,
        areaServed: {
            '@type': 'GeoShape',
            address: {
                '@type': 'PostalAddress',
                addressLocality: therapist.address.city.name,
                postalCode: therapist.address.city.zip,
                streetAddress: therapist.address.streetAndNumber,
                description: therapist.address.additionalInformation || undefined,
                addressCountry: therapist.address.city.countryCode
            }
        },
        address: {
            '@type': 'PostalAddress',
            streetAddress: therapist.address.streetAndNumber,
            addressLocality: therapist.address.city.name,
            postalCode: therapist.address.city.zip,
            description: therapist.address.additionalInformation || undefined,
            addressCountry: therapist.address.city.countryCode
        },
        contactPoint: contactInfos.map(info => {
            return {
                '@type': 'ContactPoint',
                contactType: info.type,
                telephone: info.type === 'phone' ? info.value : undefined,
                email: info.type === 'email' ? info.value : undefined
            }
        }),
        sameAs: profiles.map(profile => profile.value),
        openingHours: therapist.openings.map(
            opening =>
                `${opening.day.slice(0, 1).toUpperCase()}${opening.day.slice(1, 2)} ${DateTime.fromISO(opening.startTime).toFormat('HH:mm')}-${DateTime.fromISO(opening.endTime).toFormat('HH:mm')}`
        ),
        knowsLanguage: therapist.languages.map(language => language.language),
        knowsAbout: therapist.characteristics
            .filter(entry => entry.category === TherapistCharacteristic.category.CORNERSTONES || entry.category === TherapistCharacteristic.category.PROCEDURES)
            .map(entry => entry.label),
        founder: {
            '@type': 'Person',
            name: `${therapist.firstName ?? ''} ${therapist.lastName ?? ''}`,
            jobTitle: therapist.title || undefined,
            gender: therapist.gender && therapist.gender !== PublicTherapist.gender.NOT_SET ? therapist.gender : undefined,
            email: therapist.contactInfos.find(information => information.type === 'email')?.value,
            telephone: therapist.contactInfos.find(information => information.type === 'phone')?.value
        }
    }
}
