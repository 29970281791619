import { getServerSideTranslations } from '@tebuto/i18n/server'
import { getSocialProfiles } from '@tebuto/layout/landing/socialData'
import { Organization, WithContext } from 'schema-dts'
import { tebutoFounder, tebutoTeam } from './general'

export async function JsonLdOrganization(): Promise<WithContext<Organization>> {
    const { t } = await getServerSideTranslations('components')
    const socialProfiles = getSocialProfiles(t)
    return {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': 'https://tebuto.de/#organization',
        name: 'Tebuto',
        alternateName: 'Tebuto GmbH',
        legalName: 'Tebuto GmbH',
        description: 'Wir wollen Psycholog:innen und Psychotherapeut:innen unterstützen, ihre Energie auf das zu konzentrieren, was wirklich zählt: ihre Klient:innen.',
        slogan: 'Online-Terminbuchung für Therapie, Beratung und Coaching auf Selbstzahlerbasis',
        foundingDate: '2024-07-25',
        foundingLocation: 'Karlsruhe, Germany',
        vatID: 'DE369855931',
        knowsLanguage: ['German', 'English'],
        telephone: '+49 721 4671 2023',
        email: 'hello@tebuto.de',
        location: 'Karlsruhe, Germany',
        areaServed: 'Germany',
        address: {
            '@type': 'PostalAddress',
            streetAddress: 'Wilhelmstraße 18',
            postalCode: '76344',
            addressLocality: 'Eggenstein-Leopoldshafen',
            addressCountry: 'DE'
        },
        brand: {
            '@type': 'Brand',
            '@id': 'https://tebuto.de/#brand',
            name: 'Tebuto',
            description: 'Tebuto ist eine Marke, die innovative Lösungen für Psycholog:innen und Therapeut:innen bietet, um ihre Praxis effizienter zu organisieren.',
            logo: 'https://tebuto.de/assets/logo.svg',
            url: 'https://tebuto.de',
            sameAs: socialProfiles.map(profile => profile.href),
            alternateName: 'Tebuto - Online-Terminbuchung',
            slogan: 'Online-Terminbuchung für Therapie, Beratung und Coaching auf Selbstzahlerbasis'
        },
        owns: [
            {
                '@id': 'https://tebuto.de/#app'
            },
            {
                '@id': 'https://tebuto.de/#website'
            },
            {
                '@id': 'https://tebuto.de/#brand'
            }
        ],
        makesOffer: [{ '@id': 'https://tebuto.de/#app' }, { '@id': 'https://tebuto.de/#website' }],
        founder: tebutoFounder,
        employee: tebutoTeam,
        // subjectOf: {}, Backlinking articles official namings etc.
        publishingPrinciples: 'https://tebuto.de/ueber-uns',
        ethicsPolicy: 'https://tebuto.de/ueber-uns',
        numberOfEmployees: {
            '@type': 'QuantitativeValue',
            value: 2,
            unitText: 'Mitarbeiter'
        },
        url: 'https://tebuto.de',
        logo: 'https://tebuto.de/assets/logo.svg',
        sameAs: socialProfiles.map(profile => profile.href),
        image: 'https://tebuto.de/assets/tebuto.png',
        contactPoint: [
            {
                '@type': 'ContactPoint',
                contactType: 'Customer Service and Support',
                areaServed: 'DE',
                hoursAvailable: [
                    {
                        '@type': 'OpeningHoursSpecification',
                        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                        opens: '09:00',
                        closes: '17:00',
                        validFrom: '2024-10-01',
                        description: 'Der Kundenservice ist von Montag bis Freitag von 9:00 bis 17:00 Uhr erreichbar. Bei dringenden Fällen auch außerhalb dieser Zeiten.',
                        url: 'https://tebuto.de/kontakt'
                    }
                ],
                telephone: '+49 721 4671 2023',
                email: 'support@tebuto.de',
                availableLanguage: ['de']
            },
            {
                '@type': 'ContactPoint',
                contactType: 'General Inquiries',
                areaServed: 'DE',
                hoursAvailable: [
                    {
                        '@type': 'OpeningHoursSpecification',
                        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                        opens: '09:00',
                        closes: '17:00',
                        validFrom: '2024-10-01',
                        description: 'Allgemeine Anfragen werden während der Geschäftszeiten bearbeitet.',
                        url: 'https://tebuto.de/kontakt'
                    }
                ],
                telephone: '+49 721 4671 2023',
                email: 'hello@tebuto.de',
                availableLanguage: ['de', 'en']
            }
        ]
    }
}
