// in the future we could rather offer a function to get the translations for a specific language

export const TRANSLATIONS = {
    menu: {
        usage: 'Funktionen',
        waitlist: 'Warteliste'
    },
    landing: {
        hero: {
            title: 'Online-Terminbuchung für Therapie, Beratung und Coaching auf Selbstzahlerbasis',
            subtitle: 'Mehr Zeit für die wichtigen Dinge.',
            introduction1:
                'Vereinfachen Sie Terminplanung und Datenverwaltung. Automatisieren Sie Erinnerungen und Kommunikation mit Klienten – alles auf einer intuitiven, datenschutzkonformen Plattform.',
            '30daysFreePart1': '30 Tage lang',
            '30daysFreePart2': 'Risiko- und kostenfrei testen'
        },
        baseLineSummary: {
            title: 'Unsere Grundsätze',
            description:
                'Tebuto ist ein deutsches Unternehmen mit Sitz in Karlsruhe. Wir sind ein Team von Software-Experten, das sich auf die Entwicklung einer Terminbuchungslösung für Psycholog:innen und Therapeut:innen spezialisiert hat. Unser Ziel ist es, Ihnen die Arbeit zu erleichtern und Ihren Klienten und Kunden eine einfache und ansprechende Möglichkeit der Terminbuchung zu bieten.',
            dataPrivacy: {
                title: 'Höchster Datenschutz',
                description:
                    'Klienten- und Kundendaten sind sehr sensitiv und sollten unter keinen Umständen in die falschen Hände geraten. Natürlich erfüllen wir die Anforderungen der Datenschutzgrundverordnung (DSGVO). Wir gehen aber noch einen Schritt weiter und sichern Ihre Daten an vielen Stellen noch ein wenig umfassender.'
            },
            externalFinancing: {
                title: 'Keine externen Geldgeber',
                description:
                    'Tebuto wird durch die Gründer selbst finanziert. Bei uns gibt es keine externen Geldgeber oder Investoren, die Einfluss auf unsere Entscheidungen nehmen. Allein Ihre Zufriedenheit und Zeitersparnis leiten unsere Arbeit.'
            },
            communityDrivenDevelopment: {
                title: 'Nutzergetriebene Entwicklung',
                description:
                    'Wir sind der Überzeugung, dass gute Software durch regelmäßiges Feedback und sinnvolle Anpassungen entsteht. Deshalb entwickeln wir Tebuto in enger Zusammenarbeit mit Ihnen. Regelmäßig können Sie über neue Funktionalitäten und Änderungen abstimmen, die wir bei entsprechendem Bedarf umsetzen.'
            }
        },
        waitlist: {
            CTAPart1: 'Sie möchten Ihre Terminvergabe deutlich vereinfachen?',
            CTAPart2: 'Wir informieren Sie über alle Neuigkeiten zu Tebuto.',
            processingAgreement: 'Ich stimme der Verarbeitung meiner E-Mail-Adresse zum Zweck der Registrierung auf der Warteliste und der Kontaktaufnahme durch Tebuto zu.',
            register: 'Eintragen',
            emailPlaceholder: 'Ihre E-Mail Adresse',
            successMessage: 'Danke für Ihr Interesse! Wir werden Sie informieren, sobald es Neuigkeiten gibt.',
            errors: {
                agreementMissing: 'Bitte stimmen Sie der Verarbeitung Ihrer E-Mail-Adresse zu.',
                emailMissing: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
                emailInvalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
                generic: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
            }
        },
        features: {
            appointments: {
                subTitle: 'Sparen Sie Zeit und Geld mit einer flexiblen Lösung bei voller Kontrolle.',
                title: 'Termine erstellen',
                description:
                    'Ermöglichen Sie Ihren Klienten maximale Flexibilität bei der Terminwahl und und behalten Sie dabei so viel Kontrolle wie Sie wünschen. Sie können frei gestalten: Termine zu regelmäßigen Zeiten anbieten, individuell auf Terminwünsche von Klienten eingehen oder beides kombinieren. Tebuto unterstützt Sie in Ihrer Termingestaltung durch intelligente automatisierte Prozesse.',
                features: {
                    ruleBasedAppointments: {
                        name: 'Serientermine',
                        description: 'Ihre Termine finden immer zu bestimmten Zeiten statt? Mit Tebuto generieren Sie ganz automatisch Termine nach Ihren Vorgaben.'
                    },
                    integrateCalendars: {
                        name: 'Kalender integrieren',
                        description:
                            'Private und berufliche Termine kombinieren. Integrieren Sie Ihren privaten Google, Apple oder Outlook-Kalender und lassen Sie Tebuto automatisch Ihre privaten Termine berücksichtigen.'
                    },
                    clientFlexibility: {
                        name: 'Individuelle Terminvergabe',
                        description:
                            'Sie möchten flexibel auf die Terminwünsche Ihrer Klienten eingehen? Mit der Terminverwaltung von Tebuto können Sie diese Flexibilität auf effiziente Weise bieten und jederzeit den Überblick behalten.'
                    }
                }
            },
            shareAppointments: {
                subTitle: 'Sichere und einfache Abstimmung von Terminen mit Klienten',
                title: 'Sicherer Zugang für Klienten, ganz ohne Passwörter',
                description:
                    'Terminabsprachen können kompliziert und aufwändig sein. Das muss nicht sein. Ihre Klienten sehen in Tebuto alle aktuell verfügbaren Termine und wählen nach eigenen Wünschen Termine aus. Sie bestätigen den Termin anschließend nur. Oder Sie akzeptieren gebuchte Termine automatisch. Ganz wie Sie möchten. Sie sparen Zeit und Ihre Klienten stimmen Therapie und Privatleben optimal aufeinander ab.',
                features: {
                    personalClientLinks: {
                        name: 'Passwortloser Zugang für Klienten',
                        description: 'Der technische Betreuungsaufwand wird hierdurch minimiert und auch weniger technikbegeisterte Klienten kommen mit dieser Lösung zurecht.'
                    },
                    clientDataProtection: {
                        name: 'Schutz Ihrer Klienten und deren Daten',
                        description:
                            'Die Daten Ihrer Klienten sind sensibel und sollten unter keinen Umständen in die falschen Hände geraten. Deshalb schützen wir die Daten von Ihnen und Ihrer Klienten mit modernsten Technologien und höchsten Sicherheitsstandards.'
                    },
                    improvedUserExperience: {
                        name: 'Verbesserte Nutzererfahrung',
                        description:
                            'Ihre Klienten können ganz bequem und in Ruhe den Termin auswählen, der aktuell am besten passt. Dabei setzen wir auf eine minimalistische und intuitive Oberfläche, die sich auf das Wesentliche konzentriert.'
                    }
                }
            },
            communication: {
                subTitle: 'Terminerinnerungen, Ausfallhonorare und Infomaterial',
                title: 'Kommunikation automatisieren',
                description:
                    'Viele Ihrer Alltagsprozesse sind trotz unterschiedlicher Klienten ähnlich oder sogar gleich. Tebuto hilft Ihnen, diese Prozesse zu automatisieren und spart so Zeit und verhindert Fehler.',
                features: {
                    flexibleEventNotifications: {
                        name: 'Flexible, anlassbasierte Benachrichtigungen',
                        description:
                            'Der Kontakt zwischen Ihnen und Ihren Klienten erfolgt in aller Regel bei bestimmten Ereignissen oder Zeitpunkten. Diese nutzen wir als Startpunkt für verschiedene Benachrichtigungen für Terminbestätigungen, Terminerinnerungen, Infomaterial oder auch die Abrechnung von Ausfallhonoraren.'
                    },
                    automationWithoutLoss: {
                        name: 'Automatisierung mit persönlicher Kommunikation',
                        description:
                            'Niemand mag generische Ansprachen. Deshalb können Sie alle Benachrichtigungen personalisieren und an Ihre Bedürfnisse sowie die Ihrer Klienten anpassen.'
                    },
                    avoidMistakes: {
                        name: 'Fehler vermeiden',
                        description:
                            'Tebuto unterstützt Sie dabei, Fehler in der Kommunikation zu vermeiden. Durch einfach zu bedienende Platzhalter stellen wir sicher, dass in der persönlichen Ansprache keine Fehler passieren und alle Daten korrekt sind.'
                    }
                }
            }
        },
        stats: {
            title: 'Zeit und Geld sparen',
            description:
                'Termine und Klienten im Blick zu behalten ist wesentlicher Bestandteil der täglichen Arbeit von Psycholog:innen und Therapeut:innen. Wir helfen Ihnen dabei, viele Prozesse zu automatisieren, damit Sie flexibel sind und Ihre Zeit effektiver nutzen können.',
            disclaimer:
                '* In unserem Rechenbeispiel reduzieren wir die Zeit, die Sie täglich mit der Verwaltung Ihrer Termine und Klienten verbringen, von 60 auf circa 10 Minuten. Das entspricht einer Reduktion von 10.000 Minuten im Jahr bei 200 Arbeitstagen. Bei einem durchschnittlichen Stundensatz von 80 € und einer Terminlänge von 50 Minuten, entspricht dies bereits einem gesparten Aufwand von 16.000 € im Jahr. Zu den berücksichtigten Aufgaben gehören beispielsweise Terminabsprachen, Terminerinnerungen, der Versand von Infomaterial und die Abrechnung von Ausfallhonoraren.',
            values: {
                daily: {
                    value: '50 Minuten',
                    unit: 'täglich'
                },
                yearlyTime: {
                    value: '10.000 Minuten',
                    unit: 'jährlich'
                },
                yearlyMoney: {
                    value: '16.000 €',
                    unit: 'jährlich'
                }
            }
        },
        testimonials: {
            sarah: {
                content:
                    'Bevor ich Tebuto genutzt habe, hat mich das Termin-management täglich bis zu 60min gekostet. Jetzt benötige ich dafür wöchentlich 15min und kann die frei gewordene Zeit für mich und meine Klient:innen nutzen.',
                name: 'Sarah Scholl',
                company: 'Richtungswechsler in Karlsruhe'
            }
        },
        pricing: {
            title: 'Ein Preis. Keine Tricks.',
            description:
                'Wir möchten Ihre Arbeit einfacher machen und unsere Arbeit ethisch gestalten. Deshalb gibt es bei uns keine versteckten Kosten oder Aufpreise für neue Funktionen.',
            modelName: 'Monatliches Abonnement',
            modelDescriptionPart1: 'Testen Sie Tebuto',
            modelDescriptionPart2: '30 Tage kostenlos',
            modelDescriptionPart3:
                'Anschließend erfolgt die Bezahlung bequem monatlich und Sie können jederzeit kündigen. Neue Funktionen sind automatisch für alle unsere Nutzer und ohne Aufpreis oder neue Tarife verfügbar.',
            whatsIn: 'Leistungsumfang',
            CTA: 'in die Warteliste eintragen',
            price: '69€',
            priceOccurence: 'Pro Monat',
            priceUnit: 'Euro',
            coniditions: 'Rechnungen werden monatlich online bereitgestellt und per E-Mail versendet.',
            features: {
                unlimitedAppointments: 'Unbegrenzte Anzahl an Terminen',
                unlimitedClients: 'Unbegrenzte Anzahl an Klienten',
                allFunctions: 'Zugriff auf alle Integrationen und Funktionen',
                worldwideResponsive: 'Weltweit und auf jedem Gerät verfügbar'
            }
        },
        FAQ: {
            title: 'Häufig gestellte Fragen',
            items: [
                {
                    question: 'Es gibt so viele Tools, warum sollte ich Tebuto verwenden?',
                    answer: `Tebuto wurde speziell für psychosoziale Berufsfelder entwickelt und bietet Funktionen, die genau auf diesen Bereich zugeschnitten sind. Termine nur für bestimmte Personen freizugeben und Ausfallhonorare beispielsweise, suchst du bei vielen Lösungen vergeblich. 
                    Wir entwickeln Tebuto nicht für Investoren oder Aktionäre, sondern für dich. Deshalb möchten wir von deinen Problemen hören und brauchen dein Feedback, um sie bestmöglich zu lösen. Als Software-Ingenieure wollen wir die Software jeden Tag ein bisschen besser machen, damit sie auch in Jahren noch aktuell ist. 
                    Du investierst also nicht nur in ein Tool, sondern in ein Versprechen: Wir setzen alles daran, deinen Alltag zu erleichtern.`
                },
                {
                    question: 'Ist Tebuto DSGVO-konform?',
                    answer: 'Ja. Wir erfüllen die Anforderungen der Datenschutzgrundverordnung (DSGVO) und setzen alles daran, deine Daten sowie die deiner Klienten zu schützen.'
                },
                {
                    question: 'Kann ich andere Tools, die ich bereits verwende, integrieren?',
                    answer: 'Ja. Tebuto ermöglicht die Integration bestehender Kalender (Google, Outlook), um private und berufliche Termine zu kombinieren. Zudem kannst du Zoom oder Microsoft Teams nutzen, um Termine online durchzuführen. Wir arbeiten kontinuierlich an neuen Integrationen. Falls du eine spezielle Integration benötigst, sprich uns an oder erstelle eine Anfrage auf der Roadmap.'
                },
                {
                    question: 'Gibt es eine Möglichkeit, Tebuto für sichere Online-Meetings zu nutzen?',
                    answer: 'Wenn du Microsoft Teams oder Zoom verbindest, kannst du Termine direkt über Tebuto online durchführen. Diese Dienste sind jedoch nicht standardmäßig Ende-zu-Ende verschlüsselt. Bitte informiere dich in der Dokumentation von Microsoft Teams oder Zoom, wie du deine Gespräche sicher verschlüsseln kannst. Langfristig wollen wir dich bei der sicheren Nutzung von Videokonferenz-Lösungen unterstützen. Falls du eine spezielle Integration benötigst, sprich uns an oder erstelle eine Anfrage auf der Roadmap.'
                },
                {
                    question: 'Was kostet Tebuto?',
                    answer: 'Tebuto kostet 69 € im Monat. Du kannst es 30 Tage lang kostenlos testen. Anschließend erfolgt die Bezahlung monatlich oder jährlich, und du kannst jederzeit kündigen. Neue Funktionen stehen allen Nutzern ohne Aufpreis zur Verfügung.'
                },
                {
                    question: 'Gibt es eine kostenlose Testversion?',
                    answer: 'Ja! Du kannst Tebuto 30 Tage lang kostenlos testen. Danach erfolgt die Bezahlung monatlich oder jährlich, und du kannst jederzeit kündigen.'
                },
                {
                    question: 'Welche Bezahlmöglichkeiten gibt es?',
                    answer: 'Der Rechnungsbetrag kann automatisch per Kreditkarte, PayPal oder SEPA-Lastschrift eingezogen werden. Alternativ kannst du die Rechnung per Überweisung begleichen.'
                },
                {
                    question: 'Wie kann ich das Abonnement kündigen?',
                    answer: "Du kannst dein Abonnement jederzeit kündigen. Das geht einfach über den 'Tarif wechseln'-Button in den Account-Einstellungen, indem du auf den kostenlosen Tarif wechselst."
                },
                {
                    question: 'Sind meine und die Daten meiner Patienten sicher?',
                    answer: 'Ja. Wir erfüllen die Anforderungen der Datenschutzgrundverordnung (DSGVO) und verarbeiten deine Daten ausschließlich innerhalb der EU. Wir setzen alles daran, deine Daten sowie die deiner Klienten zu schützen.'
                },
                {
                    question: 'Wie werde ich unterstützt, wenn ich Fragen oder Probleme habe?',
                    answer: 'Wir sind persönlich für dich da! Du kannst uns jederzeit per E-Mail kontaktieren, und wir antworten in der Regel innerhalb von 24 Stunden. Bei dringenden Problemen sind wir montags bis freitags zwischen 08:00 und 18:00 Uhr telefonisch erreichbar.'
                },
                {
                    question: 'Kann ich auch Rechnungen erstellen und die Abrechnung mit Krankenkassen verwalten?',
                    answer: 'Derzeit leider nein. Wir arbeiten jedoch daran, Funktionen für Rechnungen, Zahlungen und Buchhaltung in Zukunft direkt in Tebuto zu integrieren. Falls du spezielle Anforderungen hast, sprich uns an oder erstelle eine Anfrage auf der Roadmap.'
                },
                {
                    question: 'Ist Tebuto eine Praxissoftware nach KBV?',
                    answer: 'Nein. Tebuto ist keine Praxissoftware nach KBV. Du kannst es jedoch als Ergänzung zu deiner bestehenden Praxissoftware nutzen, um Terminvergabe und Kommunikation mit Klienten zu erleichtern.'
                },
                {
                    question: 'Welche Geräte und Betriebssysteme werden unterstützt?',
                    answer: 'Tebuto ist eine Webanwendung und kann auf allen Geräten mit aktuellem Webbrowser und Internetzugang genutzt werden. Wir empfehlen Google Chrome, Mozilla Firefox oder Safari.'
                },
                {
                    question: 'Wie wird die Software aktualisiert?',
                    answer: 'Tebuto wird regelmäßig aktualisiert und weiterentwickelt. Änderungen und neue Funktionen werden automatisch bereitgestellt. Du musst nichts weiter tun.'
                },
                {
                    question: 'Gibt es eine Schulung oder Anleitung zur Nutzung?',
                    answer: 'Ja. Wir bieten eine umfangreiche Dokumentation und Anleitungen zur Nutzung. Zudem unterstützen wir dich gerne bei der Einrichtung oder Nutzung.'
                }
            ]
        }
    }
}
