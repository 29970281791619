export function Heading({
    children,
    className = '',
    isH1,
    ...rest
}: { children: React.ReactNode; className?: string; isH1?: boolean; rest?: React.HTMLAttributes<HTMLHeadingElement> }) {
    if (isH1) {
        return (
            <h1
                className={`hero:text-5xl lg:text-4xl text-3xl leading-[1.1] font-semibold text-gray-900 tracking-tighter font-sans text-balance hyphens-auto ${className}`}
                {...rest}
            >
                {children}
            </h1>
        )
    }

    return (
        <h2 className={`hero:text-5xl lg:text-4xl text-3xl leading-[1.1] font-semibold text-gray-900 tracking-tighter font-sans hyphens-auto ${className}`} {...rest}>
            {children}
        </h2>
    )
}

export function LandingSection({
    children,
    className = '',
    id,
    ...rest
}: { children: React.ReactNode; className?: string; id?: string; rest?: React.HTMLAttributes<HTMLDivElement> }) {
    return (
        <section id={id} className={`max-w-6xl mx-auto max-xl:px-6 py-32 ${className}`} {...rest}>
            {children}
        </section>
    )
}

export interface IFeaturePoint {
    title: string | React.ReactNode
    description: string | React.ReactNode
    Icon: any
}

export function FeaturePoint({ feature: { Icon, title, description } }: { feature: IFeaturePoint }) {
    return (
        <div className="flex gap-6">
            <div>
                <div className="flex gap-2 max-sm:mb-4">
                    <Icon className="lg:size-8 size-7 text-primary-600 flex-shrink-0 flex-grow-0" />

                    <p className="lg:text-base text-sm font-sans text-gray-500 leading-7">
                        <span className="font-semibold text-gray-600">{title}: </span>
                        <span>{description} </span>
                    </p>
                </div>
            </div>
        </div>
    )
}
