import { Service, WithContext } from 'schema-dts'
import { TebutoServiceProps } from './types'
import { therapistAudience } from './general'

export function JsonLdService({ id, name, description, serviceUrl, freeTier = false }: TebutoServiceProps): WithContext<Service> {
    return {
        '@context': 'https://schema.org',
        '@type': 'Service',
        '@id': id,
        name: name,
        description: description,
        provider: {
            '@id': 'https://tebuto.de/#organization'
        },
        areaServed: 'DE',
        audience: therapistAudience,
        availableChannel: {
            '@type': 'ServiceChannel',
            serviceUrl: serviceUrl,
            availableLanguage: ['de']
        },
        termsOfService: 'https://tebuto.de/agb',
        offers: {
            '@type': 'Offer',
            price: freeTier ? '0' : '69',
            priceCurrency: 'EUR',
            description: freeTier ? 'Dieses Feature ist im kostenlosen Paket enthalten.' : 'Dieses Feature ist Teil des monatlichen Abonnements.',
            availability: 'https://schema.org/InStock',
            url: 'https://tebuto.de/#preis'
        }
    }
}
