import { ServerSidePublicAPI } from '../server-side'

type GoogleReview = {
    rating: 1 | 2 | 3 | 4 | 5
    name: string
    relativePublishTimeDescription: string
    text: { text: string; languageCode: string }
    originalText: { text: string; languageCode: string }
    authorAttribution: { displayName: string; uri: string; photoUri: string }
}

type GoogleProfile = {
    rating: number
    userRatingCount: number
}

type Rating = {
    count: number
    average: number
}

export async function getReviews(): Promise<GoogleReview[]> {
    const api = await ServerSidePublicAPI()
    const reviews = (await api.rating.getReviews()) as GoogleReview[]

    if (!reviews) {
        return [] // Fallback
    }

    return reviews
}

export async function getRating(): Promise<Rating> {
    const api = await ServerSidePublicAPI()
    const profile = (await api.rating.getRating()) as GoogleProfile

    if (!profile) {
        return { count: 7, average: 5 } // Fallback
    }

    return { count: profile.userRatingCount, average: profile.rating }
}
