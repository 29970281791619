import { WebSite, WithContext } from 'schema-dts'
import { generalAudience, therapistAudience } from './general'

export function JsonLdWebsite(): WithContext<WebSite> {
    const website: WithContext<WebSite> = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': 'https://tebuto.de/#website',
        publisher: {
            '@id': 'https://tebuto.de/#organization'
        },
        creator: {
            '@id': 'https://tebuto.de/#organization'
        },
        inLanguage: 'de',
        name: 'Tebuto',
        description: 'Online-Terminbuchung für Therapie, Beratung und Coaching auf Selbstzahlerbasis',
        url: 'https://tebuto.de',
        keywords: 'Tebuto, Online-Terminbuchung für Therapie, Beratung und Coaching auf Selbstzahlerbasis',
        audience: generalAudience,
        isAccessibleForFree: true,
        about: [
            {
                '@id': 'https://tebuto.de/#app'
            },
            {
                '@id': 'https://tebuto.de/#blog'
            }
        ],
        mainEntityOfPage: [
            {
                '@id': 'https://tebuto.de/#app'
            },
            {
                '@type': 'WebPage',
                '@id': 'https://tebuto.de/#roadmap',
                url: 'https://tebuto.de/roadmap',
                name: 'Roadmap',
                description: 'Offene Roadmap, um aktiv an der Entwicklung von Tebuto teilzunehmen',
                audience: generalAudience
            },
            {
                '@id': 'https://tebuto.de/#blog'
            },
            {
                '@type': 'WebPage',
                '@id': 'https://tebuto.de/#ueber-uns',
                url: 'https://tebuto.de/ueber-uns',
                name: 'Über Tebuto',
                description: 'Erfahren Sie mehr über Tebuto und das Team dahinter',
                audience: generalAudience
            },
            {
                '@type': 'WebPage',
                '@id': 'https://tebuto.de/#features',
                url: 'https://tebuto.de/features',
                name: 'Funktionen',
                description: 'Entdecken Sie die Funktionen von Tebuto',
                audience: therapistAudience
            },
            {
                '@type': 'WebPage',
                '@id': 'https://tebuto.de/#kontakt',
                url: 'https://tebuto.de/kontakt',
                description: 'Kontaktieren Sie uns',
                name: 'Kontakt',
                audience: generalAudience
            }
        ],

        potentialAction: [
            {
                '@type': 'RegisterAction',
                target: 'https://tebuto.de/registrieren',
                name: 'Registrieren für Tebuto'
            },
            {
                '@type': 'ReadAction',
                target: 'https://tebuto.de/blog',
                name: 'Psychologischer Blog'
            }
        ]
    }

    return website
}
