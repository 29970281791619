import { ServerSidePublicAPI } from '@tebuto/api/server-side'
import { Article, ProfessionalService, WithContext } from 'schema-dts'
import { generalAudience } from './general'
import { JsonLdTherapist } from './therapist'
import { IBlogPost } from '@tebuto/api/blog/tebutoClient'

export async function JsonLdArticle({ post }: { post: IBlogPost }): Promise<WithContext<Article>> {
    async function getAuthor(): Promise<ProfessionalService | { '@id': string }> {
        const tebuto = {
            '@id': 'https://tebuto.de/#organization'
        }

        // biome-ignore lint/suspicious/noDoubleEquals: We do not care about type equality here
        if (post.authorId == 0) {
            return tebuto
        }

        const api = await ServerSidePublicAPI()
        const therapist = await api.therapists.getPublicTherapistById(post.authorId).catch(console.error)

        if (!therapist) {
            return tebuto
        }

        // TODO: User ID reference when the ID is properly implemented
        return JsonLdTherapist({ therapist })
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'Article',
        '@id': `https://tebuto.de/blog/${post.slug}`,
        publisher: { '@id': 'https://tebuto.de/#organization' },
        headline: post.title,
        image: [post.cover?.url ?? undefined],
        dateCreated: post.createdAt,
        datePublished: post.createdAt,
        dateModified: post.updatedAt,
        wordCount: post.wordCount,
        timeRequired: `${post.readingTime} minutes`,
        url: `https://tebuto.de/blog/${post.slug}`,
        sourceOrganization: {
            '@id': 'https://tebuto.de/#organization'
        },
        inLanguage: 'de',
        isAccessibleForFree: true,
        copyrightYear: new Date().getFullYear(),
        description: post.excerpt,
        abstract: post.excerpt,
        // comment:
        // commentCount:
        accessMode: 'textual',
        audience: generalAudience,
        author: await getAuthor()
    }
}
